import { FC, useState, createContext, useContext, useMemo, SetStateAction, Dispatch } from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
} from '../../../../../_metronic/helpers'
import { useQueryResponse, useQueryResponseData } from './QueryResponseProvider'
import { DeviceInfo } from './_models'

type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate: DeviceInfo
  setItemIdForUpdate: Dispatch<SetStateAction<DeviceInfo>>
  isAllSelected: boolean
  disabled: boolean

  selectedObject: DeviceInfo
  setSelectedObject: Dispatch<SetStateAction<DeviceInfo>>


}

const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  itemIdForUpdate: {},
  setItemIdForUpdate: () => { },

  isAllSelected: false,
  disabled: false,

  selectedObject: {},
  setSelectedObject: () => { },

}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<DeviceInfo>(initialListView.itemIdForUpdate)
  const { isLoading } = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const [selectedObject, setSelectedObject] = useState<DeviceInfo>(initialListView.selectedObject)
  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },

        selectedObject,
        setSelectedObject,

      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProvider, useListView }
