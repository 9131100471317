import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {defaultConfig, DynamicConfig} from '../../../../configuration/config'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import {AuthModel, City, LocationArr, UserModel} from './_models'
import {dangXuat, getUserByToken} from './_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void

  locations: Array<LocationArr> | undefined
  setLocations: Dispatch<SetStateAction<Array<LocationArr> | undefined>>
  deviceType: number | undefined
  setDeviceType: Dispatch<number | undefined>
  isLed: boolean | undefined
  setIsLed: Dispatch<boolean | undefined>

  cityInfo: City | undefined
  setCityInfo: Dispatch<City | undefined>
  saveCity: (auth: City | undefined) => void

  lstImei: Array<string> | undefined
  setLstImei: Dispatch<SetStateAction<Array<string> | undefined>>

  addressD: string | undefined
  setAddressD: Dispatch<string | undefined>

  configs: DynamicConfig | undefined
  setConfigs: Dispatch<DynamicConfig>

  saveConfigs: () => void

  citySelectedAuth: City | undefined
  setCitySelectedAuth: Dispatch<City | undefined>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},

  locations: [],
  setLocations: () => {},
  deviceType: undefined,
  setDeviceType: () => {},

  isLed: undefined,
  setIsLed: () => {},

  cityInfo: undefined,
  setCityInfo: () => {},
  saveCity: () => {},

  lstImei: [],
  setLstImei: () => {},

  addressD: undefined,
  setAddressD: () => {},

  configs: undefined,
  setConfigs: () => {},

  saveConfigs: () => {},

  citySelectedAuth: undefined,
  setCitySelectedAuth: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())

  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const [locations, setLocations] = useState<Array<LocationArr> | undefined>()
  const [deviceType, setDeviceType] = useState<number | undefined>()
  const [isLed, setIsLed] = useState<boolean | undefined>()

  const [cityInfo, setCityInfo] = useState<City | undefined>(authHelper.getCity())
  const [citySelectedAuth, setCitySelectedAuth] = useState<City | undefined>(undefined)

  const [lstImei, setLstImei] = useState<Array<string> | undefined>()

  const [addressD, setAddressD] = useState<string | undefined>()

  const [configs, setConfigs] = useState<DynamicConfig | undefined>(defaultConfig)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
    authHelper.getConfigs().then((data) => {
      return setConfigs(data)
    })
  }

  const saveConfigs = () => {
    authHelper.getConfigs().then((data) => {
      return setConfigs(data)
    })
  }

  const logout = () => {
    if (auth?.configs) {
      dangXuat(auth?.configs)
    }
    saveAuth(undefined)
    localStorage.clear()
    setCurrentUser(undefined)
  }

  const saveCity = (city: City | undefined) => {
    setCityInfo(city)
    if (city) {
      authHelper.setCity(city)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        locations,
        setLocations,
        deviceType,
        setDeviceType,
        isLed,
        setIsLed,
        cityInfo,
        setCityInfo,
        saveCity,
        lstImei,
        setLstImei,
        addressD,
        setAddressD,
        configs,
        setConfigs,
        saveConfigs,
        citySelectedAuth,
        setCitySelectedAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, setCurrentUser, setCityInfo} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(auth?.configs, apiToken)
          if (data) {
            setCurrentUser(data)
          }

          // await getLocationByCityId().then(data => {
          //   setCityInfo(data.data)
          // })
        }
      } catch (error) {
        // console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      // try {
      //   if (!didRequest.current) {
      //     await getLocationByCityId(auth?.configs).then(data => {
      //       setCityInfo(data.data)
      //     })

      //   }
      // } catch (error) {
      //   console.log(error)
      // }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
