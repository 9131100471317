//import React, {useState} from 'react'
import axios, { AxiosResponse } from 'axios'
//import { Query } from 'react-query'
import { ID, QueryState, Response } from '../../../../../_metronic/helpers'
import { RoleGroup, QueryResponse } from './_models'
import { DynamicConfig } from '../../../../../configuration/config'

const userRoleGroup = '/userRoleGroup'

const getUserRoleGroups = (configs: DynamicConfig, state: QueryState): Promise<QueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${userRoleGroup}/get-userRoleGroupFilter`, state.filter || {
      "FromTime": null,
      "ToTime": null,
      "Name": "",
      "Info": "",
      "ListRole": "",
      "CreateByUser": ""
    }, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize
      }
    })
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getAllRoleGroups = (configs: DynamicConfig): Promise<QueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${userRoleGroup}/get-userRoleGroups`, {
      headers: {
        currentPage: 1,
        pageSize: 100
      }
    })
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getRoleGroupById = (configs: DynamicConfig, id: string): Promise<RoleGroup> => {
  return axios
    .post(`${configs.apiUrl}${userRoleGroup}/get-userRoleGroupById`, {
      "Id": id
    })
    .then((d: AxiosResponse<RoleGroup>) => d.data)
}

const createRoleGroup = (configs: DynamicConfig, r: RoleGroup): Promise<RoleGroup | undefined> => {
  return axios
    .post(`${configs.apiUrl}${userRoleGroup}/create-userRoleGroup`, {
      Name: r.Name,
      Info: r.Info,
      Roles: r.Roles
    })
    .then((response: AxiosResponse<Response<RoleGroup>>) => response.data)
    .then((response: Response<RoleGroup>) => response.data)
}

const updateRoleGroup = (configs: DynamicConfig, r: RoleGroup): Promise<RoleGroup | undefined> => {
  return axios
    .post(`${configs.apiUrl}${userRoleGroup}/update-userRoleGroup`, {
      Id: r.Id,
      Name: r.Name,
      Info: r.Info,
      Roles: r.Roles
    })
    .then((response: AxiosResponse<Response<RoleGroup>>) => response.data)
    .then((response: Response<RoleGroup>) => response.data)
}

const deleteRoleGroup = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${userRoleGroup}/delete-userRoleGroup`, { Id: id }).then(() => { })

}

// const deleteSelectedUserGroups = (configs: DynamicConfig, userIds: Array<ID>): Promise<void> => {
//   const requests = userIds.map((id) => axios.delete(`${configs.apiUrl}${userGroups}/${id}`))
//   return axios.all(requests).then(() => { })
// }

// const getUsersByGroupId = (configs: DynamicConfig, id: string): Promise<QueryResponse> => {
//   return axios
//     .post(`${configs.apiUrl}${users}/get-usersByGroupId`, [id], {
//       headers: {
//         currentPage: 1,
//         pageSize: 1000
//       }
//     })
//     .then((d: AxiosResponse<QueryResponse>) => d.data)
// }

// const removeUserInGroup = (configs: DynamicConfig, id: string, userName: string): Promise<QueryResponse> => {
//   return axios
//     .post(`${configs.apiUrl}${userGroups}/remove-userInGroup`, {
//       Id: id,
//       UserName: userName
//     })
//     .then((d: AxiosResponse<QueryResponse>) => d.data)
// }

const ExportRoleGroup = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${userRoleGroup}/export-userRoleGroup`, state.filter || {
      FromTime: null,
      ToTime: null,
      Name: '',
      Info: '',
      CreateByUser: '',
      ListRole: ''
    }, { responseType: "blob" })
    .then((d: AxiosResponse<Blob>) => d.data)
}


export { getUserRoleGroups, getRoleGroupById, createRoleGroup, updateRoleGroup, ExportRoleGroup, deleteRoleGroup, getAllRoleGroups }
