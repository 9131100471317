import {Modal} from 'antd'
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import {Role, useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import {Loading} from '../loading/Loading'
import {AudioOutlined, SettingOutlined} from '@ant-design/icons'
import CallSOSModal from './modal/CallSOSModal'
import BitrateModal from './modal/BitrateModal'

const {confirm} = Modal

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {pathname} = useLocation()
  const {currentUser, logout} = useAuth()
  const VIEW_NOTIFICATION = currentUser?.Roles.find((item: Role) => {
    return item.Value === 45
  })
  const VIEW_CALL_SOS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 75
  })
  const CONFIG_GROUP_BITRATE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 15
  })
  const [show, setShow] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  // const [notification, setNotification] = useState({title: '', body: ''})
  const [isTokenFound, setTokenFound] = useState(false)
  const [isClickIconBell, setIsClickIconBell] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalSettingOpen, setIsModalSettingOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModalSetting = () => {
    setIsModalSettingOpen(true)
  }

  const handleSettingCancel = () => {
    setIsModalSettingOpen(false)
  }
  // useEffect(() => {
  //   fetchToken(setTokenFound)
  // }, [])

  const confirmCall = () => {
    confirm({
      title: 'Gọi khẩn cấp',
      content: 'Bạn chắc chắn muốn gọi khẩn cấp không!',
      okText: 'Gọi',
      cancelText: 'Hủy',
      onOk: () => showModal(),
      bodyStyle: {backgroundColor: '#fff', borderRadius: '10px'},
    })
  }

  useEffect(() => {
    Modal.destroyAll()
  }, [pathname])

  // onMessageListener()
  //   .then((payload: any) => {
  //     setNotification({title: payload.notification.title, body: payload.notification.body})
  //     setShow(true)
  //   })
  //   .catch((err) => console.log('failed: ', err))

  // const onShowNotificationClicked = () => {
  //   setNotification({ title: "Notification", body: "This is a test notification" })
  //   setShow(true);
  // }

  let element: any = document.getElementById('biBell')
  if (element) {
    let styles = window.getComputedStyle(element, '::before')
    let content = styles.content
    if (content == '"ï†Š"') {
      setShowLoading(true)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  const clickNotification = () => {
    if (VIEW_NOTIFICATION) setIsClickIconBell(true)
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Bạn không có quyền xem thông báo!',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      })
    }
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <QuickLinks />
        {/* end::Menu wrapper */}
      </div>
      <input id='namek' type='hidden' />
      {/* ------------------------------ */}
      {/* TODO Tạm thời comment fix development */}
      {/* <Toast
        className='toast-notification'
        onClose={() => setShow(false)}
        show={show}
        delay={6000}
        autohide
        animation
      >
        <Toast.Header>
          <i className='bi bi-bell icon-bell-toast'></i>
          <h6 className='mr-auto'>{notification.title}</h6>
          <div className='flex-1'></div>
          <small>1 phút trước</small>
          <i title='Đóng' className='bi bi-x icon-x' onClick={() => setShow(false)}></i>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast> */}
      {/* <header className="App-header">
        {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
        {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
        <button onClick={() => onShowNotificationClicked()}>Show Toast</button>
      </header> */}
      {/* ----------------------------------- */}
      {/* Call SOS */}
      <div className={clsx('d-flex align-items-center')}>
        {VIEW_CALL_SOS && (
          <button type='button' className='btn btn-primary' onClick={confirmCall}>
            <AudioOutlined />
            Phát khẩn cấp
          </button>
        )}
      </div>
      <div className={clsx('d-flex align-items-center')} style={{marginLeft: '5px'}}>
        {CONFIG_GROUP_BITRATE && (
          <button type='button' className='btn btn-primary' onClick={showModalSetting}>
            <SettingOutlined />
            Tùy chọn
          </button>
        )}
      </div>
      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='divBell'
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          title={isTokenFound ? 'Thông báo' : 'Bạn chưa cho phép trình duyệt thông báo'}
          onClick={clickNotification}
        >
          {/* <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          /> */}
          <i id='biBell' className='bi bi-bell icon-bell'></i>
          <span
            className='bullet bullet-dot bg-success h-6px w-6px position-absolute animation-blink'
            style={{top: '18px'}}
          ></span>
        </div>
        <HeaderNotificationsMenu
          isClickIconBell={isClickIconBell}
          setIsClickIconBell={setIsClickIconBell}
        />

        {/* end::Menu wrapper */}
      </div>
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className='btn btn-icon btn-active-light-primary btn-custom position-relative'
          id='kt_drawer_chat_toggle'
        >
          <i className="bi bi-bell" style={{ fontSize: '21px' }}></i>
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div> */}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl(currentUser?.Url || '/media/avatars/avatar-default.png')} />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
      {showLoading && <Loading />}
      {isModalOpen && <CallSOSModal handleCancel={handleCancel} isModalOpen={isModalOpen} />}
      {isModalSettingOpen && (
        <BitrateModal handleCancel={handleSettingCancel} isModalOpen={isModalSettingOpen} />
      )}
    </div>
  )
}

export {Topbar}
