import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {defaultConfig} from '../../../../configuration/config'
import {useAuth} from '../core/Auth'
import {AuthModel} from '../core/_models'
import {getUserByToken, resendOTPCode, LoginOTP} from '../core/_requests'
import {Spin} from 'antd'

const forgotPasswordSchema = Yup.object().shape({
  CodeAuth: Yup.string().required('Mã OTP không được để trống').length(6, 'Mã OTP gồm 6 số'),
})

export function CheckOTP() {
  const {auth, configs, setCurrentUser, saveAuth, currentUser} = useAuth()
  const [isInValidOTP, setIsInValidOTP] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const userForEdit = {
    CodeAuth: '',
    TaiKhoan: auth?.TaiKhoan,
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        await LoginOTP(configs || defaultConfig, auth?.TaiKhoan || '', values.CodeAuth)
          .then(async (rs) => {
            let tmp: AuthModel = {...auth, AccessToken: ''}
            tmp.AccessToken = rs.data.AccessToken
            tmp.api_token = rs.data.AccessToken
            saveAuth(tmp)
            const {data: _user} = await getUserByToken(auth?.configs, rs.data.AccessToken || '')
            _user.api_token = tmp.AccessToken
            setCurrentUser(_user)
          })
          .catch((error) => {
            setIsInValidOTP(true)
          })
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  const handleSentEmail = async () => {
    await resendOTPCode(configs || defaultConfig, auth?.TaiKhoan || '').then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 30000)
    }
  }, [isLoading])

  return (
    <>
      <div className='w-lg-450px bg-white rounded shadow-sm p-10 p-lg-10 mx-auto'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-8'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Mã OTP</h1>
            {/* end::Title */}
          </div>
          {/* begin::Form group */}
          <div className='row'>
            <label
              className='form-label fw-bolder text-gray-900 fs-6'
              style={{textAlign: 'center'}}
            >
              Nhập mã OTP
              <i
                title='Để lấy mã OTP, vui lòng cài đặt, đăng nhập
                và mở ứng dụng RadioTech trên điện thoại, 
                vào phần Trang chủ > Thông tin tài khoản > Mã OTP'
                className='bi bi-info-circle fs-1'
                style={{
                  cursor: 'pointer',
                  color: '#009EF7',
                  paddingLeft: '5px',
                }}
              ></i>
            </label>
            <input
              style={{textAlign: 'center'}}
              placeholder=''
              autoComplete='off'
              onClick={() => {
                setIsInValidOTP(false)
              }}
              {...formik.getFieldProps('CodeAuth')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.CodeAuth && formik.errors.CodeAuth},
                {
                  'is-valid': formik.touched.CodeAuth && !formik.errors.CodeAuth,
                }
              )}
            />
            {((formik.touched.CodeAuth && formik.errors.CodeAuth) || isInValidOTP) && (
              <div className='fv-plugins-message-container text-center mt-2'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {isInValidOTP ? 'Mã OTP không đúng!' : formik.errors.CodeAuth}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div
            className='d-flex flex-wrap justify-content-center pb-lg-0'
            style={{paddingTop: '25px'}}
          >
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder me-4'
              >
                Quay lại
              </button>
            </Link>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder me-4'
              onClick={handleSentEmail}
              disabled={isLoading}
            >
              {isLoading && <Spin />}
              Gửi email
            </button>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder'
            >
              <span className='indicator-label'>Tiếp tục</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Vui lòng đợi...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}
