import axios, { AxiosResponse } from 'axios'
import { QueryState } from '../../../../../_metronic/helpers'
import { QueryResponse } from './_models'
import { DynamicConfig } from '../../../../../configuration/config'

const DEVICE_LOG = '/deviceLog'

const getListDeviceLog = (configs: DynamicConfig, state: QueryState): Promise<QueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_LOG}/get-displayDeviceLogFilter`, state.filter, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize
      }
    })
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const ExportListDeviceLog = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_LOG}/export-displayDeviceLog`, state.filter, { responseType: "blob" })
    .then((d: AxiosResponse<Blob>) => d.data)
}

export { getListDeviceLog, ExportListDeviceLog }
