import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import { Role, useAuth } from '../../../../modules/auth'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'

type Props = {
  roles: Array<Role> // Ds quyền của user
}

const RoleModalForm: FC<Props> = ({ roles }) => {
  const { setSelectedObject, setSelectedRoles } = useListView()
  const { currentUser } = useAuth()
  const { refetch } = useQueryResponse()
  const [lstCurrent, setLstCurrent] = useState<Array<Role>>(roles)
  const [lstOther, setLstOther] = useState<Array<Role>>(currentUser?.Roles || [])
  const [lstCurrentTmp, setLstCurrentTmp] = useState<Array<Role>>([])
  const [lstOtherTmp, setLstOtherTmp] = useState<Array<Role>>([])
  const [keySearchRoleCurrent, setKeySearchRoleCurrent] = useState('') // Từ khóa tìm kiếm quyền hiện tại
  const [keySearchRoleOther, setKeySearchRoleOther] = useState('') // Từ khóa tìm kiếm quyền khác
  const [checkBoxAllLeft, setCheckBoxAllLeft] = useState(false) // Ô checkbox chọn tất cả quyền hiện tại
  const [checkBoxAllRight, setCheckBoxAllRight] = useState(false) // Ô checkbox chọn tất cả quyền khác
  useEffect(() => {
    // lstOther.map((r: Role, i) => {
    //   if (lstCurrent.find((element) => {
    //     return element.Value === r.Value;
    //   })) {
    //     lstOther.splice(i, 1)
    //   }
    // })

    let lstTmp: Array<Role> = [...lstOther]
    for (let i = 0; i < lstTmp.length; i++) {
      for (let j = 0; j < lstCurrent.length; j++) {
        if (lstCurrent[j].Value === lstTmp[i].Value) {
          lstTmp.splice(i, 1)
          i--
          break
        }
      }
    }
    setLstOther(lstTmp)
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setSelectedObject({})
  }

  const selectAllLeft = () => {
    var x = document.getElementsByClassName('check-left') as unknown as HTMLInputElement[]
    if (checkBoxAllLeft) {
      for (let i = 0; i < x.length; i++) {
        x[i].checked = false
      }
      setLstCurrentTmp([])
    } else {
      for (let i = 0; i < x.length; i++) {
        x[i].checked = true
      }
      setLstCurrentTmp(lstCurrent)
    }
    setCheckBoxAllLeft(!checkBoxAllLeft)
  }

  const handleChangeKeySearchRoleCurrent = (event: { target: { name: any; value: any } }) => {
    setKeySearchRoleCurrent(event.target.value)
  }

  const onSelectLeft = (role: Role) => {
    let lstTmp: Array<Role> = lstCurrentTmp
    let isExits = false
    for (let i = 0; i < lstCurrentTmp.length; i++) {
      if (lstCurrentTmp[i].Value == role.Value) {
        isExits = true
        lstTmp.splice(i, 1)
        break
      }
    }
    if (!isExits)
      lstTmp.push(role)
    setLstCurrentTmp(lstTmp)
  }

  const selectAllRight = () => {
    var x = document.getElementsByClassName('check-right') as unknown as HTMLInputElement[]
    if (checkBoxAllRight) {
      for (let i = 0; i < x.length; i++) {
        x[i].checked = false
      }
      setLstOtherTmp([])
    } else {
      for (let i = 0; i < x.length; i++) {
        x[i].checked = true
      }
      setLstOtherTmp(lstOther)
    }
    setCheckBoxAllRight(!checkBoxAllLeft)
  }

  const onSelectRight = (role: Role) => {
    let lstTmp: Array<Role> = lstOtherTmp
    let isExits = false
    for (let i = 0; i < lstOtherTmp.length; i++) {
      if (lstOtherTmp[i].Value == role.Value) {
        isExits = true
        lstTmp.splice(i, 1)
        break
      }
    }
    if (!isExits)
      lstTmp.push(role)
    setLstOtherTmp(lstTmp)
  }

  const handleChangeKeySearchRoleOther = (event: { target: { name: any; value: any } }) => {
    setKeySearchRoleOther(event.target.value)
  }

  const moveLeft = () => {
    // setClickMoveLeft(true)
    let lstTmp: Array<Role> = [...lstCurrent]
    for (let i = 0; i < lstTmp.length; i++) {
      for (let j = 0; j < lstCurrentTmp.length; j++) {
        if (lstCurrentTmp[j].Value === lstTmp[i].Value) {
          lstTmp.splice(i, 1)
          i--
          break
        }
      }
    }
    setLstCurrent(lstTmp)
    setLstOther([...lstOther, ...lstCurrentTmp])
    setLstCurrentTmp([])
    setLstOtherTmp([])
    if (checkBoxAllLeft)
      setCheckBoxAllLeft(false)
    var x = document.getElementsByClassName('check-g') as unknown as HTMLInputElement[]
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
  }

  const moveRight = () => {
    // setClickMoveLeft(true)
    let lstTmp: Array<Role> = [...lstOther]
    for (let i = 0; i < lstTmp.length; i++) {
      for (let j = 0; j < lstOtherTmp.length; j++) {
        if (lstOtherTmp[j].Value === lstTmp[i].Value) {
          lstTmp.splice(i, 1)
          i--
          break
        }
      }
    }
    setLstOther(lstTmp)
    setLstCurrent([...lstCurrent, ...lstOtherTmp])
    setLstCurrentTmp([])
    setLstOtherTmp([])
    if (checkBoxAllRight)
      setCheckBoxAllRight(false)
    var x = document.getElementsByClassName('check-g') as unknown as HTMLInputElement[]
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
  }

  const save = () => {
    // if (clickMoveLeft || clickMoveRight) {
    //   let element = document.getElementById('div-role')
    //   if (element) {
    //     let tmp = ''
    //     if (lstRoleCurrent.length < 3) {
    //       tmp += `<div class='div-group'>`
    //       {
    //         lstRoleCurrent.map(
    //           (role: Role) => (tmp += `<span class='span-user'>${role.Name}</span>`)
    //         )
    //       }
    //       tmp += `</div>`
    //     } else if (lstRoleCurrent.length > 2)
    //       tmp = `<div class='div-group'>
    //               <span class='span-user'>${lstRoleCurrent[0].Name}</span>
    //               <span class='span-user'>${lstRoleCurrent[1].Name}</span>
    //               <button type='button' class='span-user'>+${lstRoleCurrent.length - 2}</button>
    //             </div>`
    //     element.innerHTML = tmp
    //   }
    //   setRolesNew(lstRoleCurrent)
    //   setClickedRole(true)
    // }
    // setUserSelectedRole({})
    setSelectedRoles(lstCurrent)
    cancel()
  }

  return (
    <>
      <form id='kt_modal_group_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_group_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div id='rowUserGroup' className='row'>
            <div className='col-5'>
              {/* begin::Search */}
              <div className='form-check d-flex align-items-center position-relative'>
                <input
                  className='form-check-input check-g check-all'
                  type='checkbox'
                  id='checkBoxAllLeft'
                  checked={checkBoxAllLeft}
                  onChange={() => selectAllLeft()}
                  title='Chọn tất cả'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid'
                  placeholder='Quyền hiện tại ...'
                  onChange={handleChangeKeySearchRoleCurrent}
                  style={{ paddingRight: 36 }}
                />
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute icon-search'
                />
              </div>

              {/* end::Search */}
              <div id='div-left' className='div-checkbox'>
                {lstCurrent.map((role: Role, i) => {
                  return (
                    role.Name?.toLowerCase().includes(keySearchRoleCurrent.toLowerCase()) && (
                      <div
                        key={i * 100 + 1}
                        className='form-check form-check-sm form-check-custom form-check-solid mt-5'
                      >
                        <input
                          style={{ marginRight: '10px' }}
                          className='form-check-input check-g check-left'
                          type='checkbox'
                          id={`${role.Value}`}
                          onChange={() => onSelectLeft(role)}
                        />
                        <label
                          title={role.Name}
                          htmlFor={`${role.Value}`}
                          className='lable-checkbox'
                        >
                          {role.Name}
                        </label>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
            <div className='col-2'>
              <div className='row col-12 justify-content-center div-btn-move'>
                <button
                  type='button'
                  className='btn-move col-6'
                  title='Chuyển sang'
                  onClick={() => moveLeft()}
                >
                  <i className='bi bi-arrow-right bt-next'></i>
                </button>
                <button
                  type='button'
                  className='btn-move col-6 mt-5'
                  title='Chuyển sang'
                  onClick={() => moveRight()}
                >
                  <i className='bi bi-arrow-left bt-next'></i>
                </button>
              </div>
              <div className='row'></div>
            </div>
            <div className='col-5'>
              {/* begin::Search */}
              <div className='form-check d-flex align-items-center position-relative'>
                <input
                  className='form-check-input check-g check-all'
                  type='checkbox'
                  id='checkBoxAllRight'
                  checked={checkBoxAllRight}
                  onChange={() => selectAllRight()}
                  title='Chọn tất cả'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid'
                  placeholder='Quyền khác ...'
                  onChange={handleChangeKeySearchRoleOther}
                  style={{ paddingRight: 36 }}
                />
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute icon-search'
                />
              </div>
              {/* end::Search */}
              <div className='div-checkbox'>
                {lstOther.map((role: Role, i) => {
                  return (
                    role.Name?.toLowerCase().includes(keySearchRoleOther.toLowerCase()) && (
                      <div
                        key={i}
                        className='form-check form-check-sm form-check-custom form-check-solid mt-5'
                      >
                        <input
                          style={{ marginRight: '10px' }}
                          className='form-check-input check-g check-right'
                          type='checkbox'
                          id={`${role.Value}`}
                          onChange={() => onSelectRight(role)}
                        />
                        <label
                          title={role.Name}
                          htmlFor={`${role.Value}`}
                          className='lable-checkbox'
                        >
                          {role.Name}
                        </label>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='button'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
          >
            Trở lại
          </button>

          <button type='button' className='btn btn-primary'
            onClick={() => save()}
          >
            <span className='indicator-label'>Cập nhật</span>
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </>
  )
}

export { RoleModalForm }
