import { ListToolbar } from './ListToolbar'

const ListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1'>
          Đồng bộ dữ liệu
        </div>
        {/* end::Search */}
      </div>
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { ListHeader }
