import {JitsiMeeting} from '@jitsi/react-sdk'
import {Modal, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../../app/modules/auth'
import {Loading} from '../../loading/Loading'
import {
  callSOS,
  pingStreamState,
  stopStreaming,
} from '../../../../../app/pages/phattructiep/streaming-list/core/_requests'
import {PingState} from '../../../../../app/pages/phattructiep/streaming-list/core/_models'
import generateCallId from '../../../../../app/pages/phattructiep/streaming-list/core/generate-call-id'

const LOCAL_MEET = 'meeting.truyenthanhso.com'

const renderSpinner = () => {
  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      Đang tải...
    </div>
  )
}

interface CallSOSModalProps {
  isModalOpen: boolean
  handleCancel: any
  isJoin?: boolean
}

const CallSOSModal = ({isModalOpen, handleCancel, isJoin}: CallSOSModalProps) => {
  const {auth, currentUser} = useAuth()
  const [callId, setCallId] = useState('')
  const [isDisplayBtn, setIsDisplayBtn] = useState(true)

  const leaveMeetingCallMutation = useMutation({
    mutationFn: (callId: string) => stopStreaming(auth?.configs, callId),
  })

  const pingMutation = useMutation({
    mutationFn: (payload: PingState) => pingStreamState(auth?.configs, payload),
  })

  useEffect(() => {
    const ping = setInterval(() => {
      pingMutation.mutate({CallId: callId})
    }, 30000)

    return () => {
      if (ping) clearInterval(ping)
    }
  }, [callId, pingMutation])

  const {isLoading} = useQuery({
    queryKey: ['call_sos'],
    queryFn: () =>
      callSOS(auth?.configs, callId).catch((error) => {
        handleCancel()
        Swal.fire({
          icon: 'error',
          title: error.response.data,
          text: ' ',
          confirmButtonText: 'Đóng lại',
        })
      }),
    enabled: !!callId,
  })

  useEffect(() => {
    setCallId(generateCallId())
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDisplayBtn(false)
    }, 8000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Modal
      className='nguoi-dung-body'
      title={
        <Typography.Title level={3} style={{margin: 0}}>
          Phát khẩn cấp
        </Typography.Title>
      }
      width={800}
      open={isModalOpen}
      closable={!isDisplayBtn}
      onCancel={() => {
        leaveMeetingCallMutation.mutateAsync(callId)
        handleCancel()
      }}
      maskClosable={false}
      centered
      destroyOnClose
      bodyStyle={{padding: '16px'}}
      footer={
        <div className='text-center mt-5' key='action'>
          <button
            type='reset'
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
            onClick={() => {
              leaveMeetingCallMutation.mutateAsync(callId)
              handleCancel()
            }}
            disabled={isDisplayBtn}
          >
            Kết thúc cuộc gọi
          </button>
        </div>
      }
    >
      <JitsiMeeting
        domain={auth?.configs.localMeet ?? LOCAL_MEET}
        roomName={callId}
        spinner={renderSpinner}
        configOverwrite={{
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          disableAP: true,
          disableAEC: true,
          disableAGC: true,
          disableModeratorIndicator: true,
          enableEmailInStats: false,
          audioQuality: {
            stereo: false,
            opusMaxAverageBitrate: 32000, // Value to fit the 6000 to 510000 range.
            enableOpusDtx: false,
          },
          buttonsWithNotifyClick: [
            {
              key: 'camera',
              preventExecution: false,
            },
            {
              key: 'chat',
              preventExecution: true,
            },
            {
              key: 'closedcaptions',
              preventExecution: false,
            },
            'desktop',
            'download',
            'embedmeeting',
            'etherpad',
            'feedback',
            'filmstrip',
            {
              key: 'fullscreen',
              preventExecution: false,
            },
            {
              key: 'hangup',
              preventExecution: false,
            },
            'help',
            {
              key: 'invite',
              preventExecution: true,
            },
            'livestreaming',
            {
              key: 'microphone',
              preventExecution: false,
            },
            'mute-everyone',
            'mute-video-everyone',
            'participants-pane',
            {
              key: 'profile',
              preventExecution: false,
            },
            {
              key: 'raisehand',
              preventExecution: true,
            },
            {
              key: 'recording',
              preventExecution: false,
            },
            {
              key: 'security',
              preventExecution: false,
            },
            {
              key: 'select-background',
              preventExecution: false,
            },
            {
              key: 'settings',
              preventExecution: false,
            },
            {
              key: 'shareaudio',
              preventExecution: false,
            },
            {
              key: 'sharedvideo',
              preventExecution: false,
            },
            {
              key: 'shortcuts',
              preventExecution: false,
            },
            'stats',
            {
              key: 'tileview',
              preventExecution: true,
            },
            {
              key: 'toggle-camera',
              preventExecution: false,
            },
            {
              key: 'videoquality',
              preventExecution: false,
            },
            // The add passcode button from the security dialog.
            {
              key: 'add-passcode',
              preventExecution: false,
            },
            {
              key: '__end',
              preventExecution: false,
            },
          ],
        }}
        onReadyToClose={() => {
          leaveMeetingCallMutation.mutateAsync(callId)
          handleCancel()
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = '500px'
        }}
        userInfo={{
          displayName: currentUser?.FullName!,
          email: isJoin ? currentUser?.Email! : (currentUser?.Id + '_' + callId)!,
        }}
      />
      {isLoading && <Loading />}
    </Modal>
  )
}

export default CallSOSModal
