import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {Role} from '../../../../modules/auth'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
//import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {RoleGroup} from '../core/_models'
import {createRoleGroup, updateRoleGroup} from '../core/_requests'

type Props = {
  isUserLoading: boolean
  roleGroup: RoleGroup
}

const editUserSchema = Yup.object().shape({
  Name: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Tên nhóm không được để trống'),
  Info: Yup.string()
    .trim()
    // .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự'),
  // .required('Mô tả không được để trống'),
  Roles: Yup.array().min(1, 'Bạn chưa chọn quyền'),
})

const EditModalForm: FC<Props> = ({roleGroup, isUserLoading}) => {
  const {setItemIdForUpdate, setSelectedObject, selectedRoles, setSelectedRoles} = useListView()
  const {refetch} = useQueryResponse()
  const {auth} = useAuth()

  useEffect(() => {
    setSelectedRoles(roleGroup.Roles || [])
  }, [])

  useEffect(() => {
    formik.setFieldValue('Roles', selectedRoles)
  }, [selectedRoles])

  const [userGroupForEdit] = useState<RoleGroup>({
    ...roleGroup,
    Name: roleGroup.Name || '',
    Info: roleGroup.Info || '',
    Roles: roleGroup.Roles || [],
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setSelectedRoles([])
    setItemIdForUpdate('')
  }

  const formik = useFormik({
    initialValues: userGroupForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        values.Roles = selectedRoles
        if (values.Id !== '0') {
          await updateRoleGroup(auth?.configs, values)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Cập nhật thông tin thành công',
                text: ' ',
                showConfirmButton: false,
                timer: 1500,
              })
              cancel(true)
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: 'Cập nhật thông tin thất bại',
                text: ' ',
                confirmButtonText: 'Đóng lại',
              })
            })
        } else {
          await createRoleGroup(auth?.configs, values)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Thêm nhóm thành công',
                text: ' ',
                showConfirmButton: false,
                timer: 1500,
              })
              cancel(true)
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: 'Thêm nhóm thất bại',
                text: ' ',
                confirmButtonText: 'Đóng lại',
              })
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Tên nhóm</label>
              <input
                placeholder='Tên nhóm'
                {...formik.getFieldProps('Name')}
                type='text'
                name='Name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Name && formik.errors.Name},
                  {
                    'is-valid': formik.touched.Name && !formik.errors.Name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.Name && formik.errors.Name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Name}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Mô tả</label>
              <textarea
                placeholder='Mô tả'
                {...formik.getFieldProps('Info')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                name='Info'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {/* end::Input */}
              {formik.touched.Info && formik.errors.Info && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.Info}
                  </span>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Danh sách quyền</label>
              <div className='row m-0'>
                <div
                  id='div-role-device-group'
                  className='col-10 div-role'
                  style={{minHeight: '41px'}}
                >
                  {selectedRoles && selectedRoles.length < 3 && (
                    <div className='div-group'>
                      {selectedRoles.map((r: Role) => (
                        <span key={r.Value} className='span-user'>
                          {r.Name}
                        </span>
                      ))}
                    </div>
                  )}
                  {selectedRoles && selectedRoles.length > 2 && (
                    <div className='div-group'>
                      <span className='span-user'>{selectedRoles[0].Name}</span>
                      <span className='span-user'>{selectedRoles[1].Name}</span>
                      <button type='button' className='span-user'>
                        +{selectedRoles.length - 2}
                      </button>
                    </div>
                  )}
                </div>
                <div className='col-2 text-right'>
                  <button
                    className='bi bi-pencil-fill fs-1 update btn-open-modal'
                    onClick={() => setSelectedObject(roleGroup)}
                    type='button'
                    style={{
                      cursor: 'pointer',
                      padding: '5px 7px',
                      color: '#1e2ec0',
                      background: '#FFF',
                      border: '#1e2ec0 solid 1px',
                      borderRadius: '5px',
                    }}
                  ></button>
                </div>
              </div>
              {formik.touched.Roles && formik.errors.Roles && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.Roles}
                  </span>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
          >
            Hủy bỏ
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{roleGroup.Id === '0' ? 'Thêm mới' : 'Cập nhật'}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loading />}
    </>
  )
}

export {EditModalForm}
