import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ThietBiListHeader} from './components/header/ThietBiListHeader'
import {ThietBiTable} from './table/ThietBiTable'
import {DeviceInfoModal} from './device-info-modal/DeviceInfoModal'
import {KTCard} from '../../../../_metronic/helpers'
import {DeviceCommandModal} from './device-command-modal/DeviceCommandModal'
import {Tab, Tabs} from 'react-bootstrap'
import {QueryRequestProviderBDT} from '../bangdientu-list/core/QueryRequestProviderBDT'
import {QueryResponseProviderBDT} from '../bangdientu-list/core/QueryResponseProviderBDT'
import {ListViewProviderBDT, useListViewBDT} from '../bangdientu-list/core/ListViewProviderBDT'
import {BangDienTuTable} from '../bangdientu-list/table/BangDienTuTable'
import {BangDienTuListHeader} from '../bangdientu-list/components/header/BangDienTuListHeader'
import {FC, useEffect, useState} from 'react'
import {QueryRequestProviderNKTT} from '../nhatkytruyenthanh-list/core/QueryRequestProviderNKTT'
import {QueryResponseProviderNKTT} from '../nhatkytruyenthanh-list/core/QueryResponseProviderNKTT'
import {ListViewProviderNKTT} from '../nhatkytruyenthanh-list/core/ListViewProviderNKTT'
import {NhatKyTruyenThanhTable} from '../nhatkytruyenthanh-list/table/NhatKyTruyenThanhTable'
import {NhatKyTruyenThanhListHeader} from '../nhatkytruyenthanh-list/components/header/NhatKyTruyenThanhListHeader'
import {NhatKyBangDienTuListHeader} from '../nhatkybangdientu-list/components/header/NhatKyBangDienTuListHeader'
import {NhatKyBangDienTuTable} from '../nhatkybangdientu-list/table/NhatKyBangDienTuTable'
import {QueryRequestProviderNKBDT} from '../nhatkybangdientu-list/core/QueryRequestProviderNKBDT'
import {QueryResponseProviderNKBDT} from '../nhatkybangdientu-list/core/QueryResponseProviderNKBDT'
import {ListViewProviderNKBDT} from '../nhatkybangdientu-list/core/ListViewProviderNKBDT'
import {LedDeviceInfoModal} from '../bangdientu-list/led-device-info-modal/LedDeviceInfoModal'
import {LedDeviceEditModal} from '../bangdientu-list/led-device-edit-modal/LedDeviceEditModal'
import {LedDeviceSettingModal} from '../bangdientu-list/led-device-setting-modal/LedDeviceEditModal'
import {useAuth} from '../../../modules/auth'
import {useLocation} from 'react-router-dom'

type Props = {
  changeTab: any
}
const ThietBiList: FC<Props> = ({changeTab}) => {
  const {itemIdForUpdate, selectedObject} = useListView()
  return (
    <>
      <KTCard>
        <ThietBiListHeader changeTab={changeTab} />
        <ThietBiTable />
      </KTCard>
      {Object.keys(itemIdForUpdate).length !== 0 && <DeviceInfoModal />}
      {Object.keys(selectedObject).length !== 0 && <DeviceCommandModal />}
    </>
  )
}

const BangDienTuList: FC<Props> = ({changeTab}) => {
  const {itemIdForLed, itemIdForUpdateLed, itemIdForSettingLed} = useListViewBDT()
  return (
    <>
      <KTCard>
        <BangDienTuListHeader changeTab={changeTab} />
        <BangDienTuTable />
      </KTCard>
      {Object.keys(itemIdForLed).length !== 0 && <LedDeviceInfoModal />}
      {itemIdForUpdateLed && itemIdForUpdateLed?.length > 0 && <LedDeviceEditModal />}
      {itemIdForSettingLed && itemIdForSettingLed?.length > 0 && <LedDeviceSettingModal />}
    </>
  )
}

const NhatKyTruyenThanhList: FC<Props> = ({changeTab}) => {
  return (
    <>
      <KTCard>
        <NhatKyTruyenThanhListHeader changeTab={changeTab} />
        <NhatKyTruyenThanhTable />
      </KTCard>
    </>
  )
}

const NhatKyBangDienTuList: FC<Props> = ({changeTab}) => {
  return (
    <>
      <KTCard>
        <NhatKyBangDienTuListHeader changeTab={changeTab} />
        <NhatKyBangDienTuTable />
      </KTCard>
    </>
  )
}

const ThietBiListWrapper = () => {
  const {pathname, state} = useLocation()
  const {isLed, setIsLed, setCitySelectedAuth} = useAuth()
  const [tab, setTab] = useState(isLed === true ? 2 : 1)

  const handleSelect = (key: any) => {
    setTab(parseInt(key))
  }
  const changeTab = (value: number) => {
    setTab(value)
  }

  useEffect(() => {
    setIsLed(false)
    return () => {
      setCitySelectedAuth(undefined)
    }
  }, [])

  return (
    <>
      <Tabs
        defaultActiveKey={isLed == true ? 2 : 1}
        id='uncontrolled-tab-example'
        onSelect={handleSelect}
      >
        <Tab eventKey={1} title='Thiết bị truyền thanh'>
          {tab === 1 && (
            <QueryRequestProvider>
              <QueryResponseProvider>
                <ListViewProvider>
                  <ThietBiList changeTab={changeTab} />
                </ListViewProvider>
              </QueryResponseProvider>
            </QueryRequestProvider>
          )}
        </Tab>
        <Tab eventKey={2} title='Bảng điện tử'>
          {tab === 2 && (
            <QueryRequestProviderBDT>
              <QueryResponseProviderBDT>
                <ListViewProviderBDT>
                  <BangDienTuList changeTab={changeTab} />
                </ListViewProviderBDT>
              </QueryResponseProviderBDT>
            </QueryRequestProviderBDT>
          )}
        </Tab>
      </Tabs>
      {tab === 3 && (
        <QueryRequestProviderNKTT>
          <QueryResponseProviderNKTT>
            <ListViewProviderNKTT>
              <NhatKyTruyenThanhList changeTab={changeTab} />
            </ListViewProviderNKTT>
          </QueryResponseProviderNKTT>
        </QueryRequestProviderNKTT>
      )}
      {tab === 4 && (
        <QueryRequestProviderNKBDT>
          <QueryResponseProviderNKBDT>
            <ListViewProviderNKBDT>
              <NhatKyBangDienTuList changeTab={changeTab} />
            </ListViewProviderNKBDT>
          </QueryResponseProviderNKBDT>
        </QueryRequestProviderNKBDT>
      )}
    </>
  )
}

export {ThietBiListWrapper}
