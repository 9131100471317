import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { Loading } from '../../../../../../_metronic/layout/components/loading/Loading'
import { BtnRefetch } from '../../../../../../components'
import { Role } from '../../../../../modules/auth'
import { useAuth } from '../../../../../modules/auth/core/Auth'
import { useListView } from '../../core/ListViewProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse, useQueryResponseData } from '../../core/QueryResponseProvider'
import { Export } from '../../core/_requests'

const ListToolbar = () => {
  const {auth, currentUser} = useAuth()
  const {setItemIdForUpdate} = useListView()
  const [isLoading, setIsLoading] = useState(false)
  const data = useQueryResponseData()
  const {refetch} = useQueryResponse()

  const EDIT_CAU_HINH_DONG_BO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 66
  })
  const openAddUserModal = () => {
    setItemIdForUpdate('0')
  }

  const {state} = useQueryRequest()

  const fExport = () => {
    setIsLoading(true)
    exportUser.mutateAsync()
  }

  const exportUser = useMutation(() => Export(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `danhsachdongbodulieu.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setIsLoading(false)
    },
  })

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* <UsersListSearchComponent /> */}
        {/* <ManufactureListFilter /> */}

        {/* begin::Export */}
        <BtnRefetch refetch={refetch} />

        <button
          type='button'
          className='btn btn-primary me-3'
          onClick={fExport}
          disabled={data.length == 0}
        >
          <DownloadOutlined />
          Xuất dữ liệu
        </button>
        {/* end::Export */}

        {/* begin::Add user */}
        {EDIT_CAU_HINH_DONG_BO && (
          <button type='button' className='btn btn-primary bt-button' onClick={openAddUserModal}>
            <PlusOutlined />
            Thêm mới
          </button>
        )}
        {/* end::Add user */}
      </div>
      {isLoading && <Loading />}
    </>
  )
}

export { ListToolbar }

