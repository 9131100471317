import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { UserGroupsListHeader } from './components/header/UserGroupsListHeader'
import { NhomThietBiTable } from './table/NhomThietBiTable'
import { DaiTruyenThanhEditModal } from './device-group-edit-modal/DaiTruyenThanhEditModal'
import { KTCard } from '../../../../_metronic/helpers'
import { ListUserModal } from './table/Modal/ListUserModal'

const NhomThietBiList = () => {
  const { itemIdForUpdate } = useListView()
  return (

    <>
      <KTCard>
        <UserGroupsListHeader />
        <NhomThietBiTable />
      </KTCard>
      {itemIdForUpdate !== "" && <DaiTruyenThanhEditModal />}

      <ListUserModal />
    </>
  )
}

const NhomThietBiListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NhomThietBiList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { NhomThietBiListWrapper }
