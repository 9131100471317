//import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {TypeConfig} from '../core/_models'
import {controlDeviceInfo, getTypeConfig} from '../core/_requests'

const editUserSchema = Yup.object().shape({
  Value: Yup.string().required('Giá trị không được để trống'),
})

const DeviceCommandModalForm = () => {
  const {auth} = useAuth()

  const {setSelectedObject, selectedObject} = useListView()
  const {refetch} = useQueryResponse()

  const [manufactureForEdit] = useState<any>({
    ...selectedObject,
    Value: '',
    Control: 1002,
  })

  const [listTypeConfig, setListTypeConfig] = useState<Array<TypeConfig>>([])
  const [isShowControlDevice, setIsShowControlDevice] = useState<boolean>(false)

  useEffect(() => {
    getTypeConfig(auth?.configs).then((data) => {
      if (data) setListTypeConfig(data)
    })
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setSelectedObject({})
  }

  const formik = useFormik({
    initialValues: manufactureForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await controlDeviceInfo(auth?.configs, values.Imei, values.Control, values.Value)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Gửi lệnh thành công',
              text: ' ',
              showConfirmButton: false,
              timer: 1500,
            })
            cancel(true)
          })
          .catch((error) =>
            Swal.fire({
              icon: 'error',
              title: error.response.data || 'Gửi lệnh thất bại',
              text: ' ',
              confirmButtonColor: '#009ef7',
              confirmButtonText: 'Đóng lại',
            })
          )
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-12'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>IMEI</label>
              <input
                placeholder='IMEI'
                {...formik.getFieldProps('Imei')}
                type='string'
                name='Imei'
                disabled
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Imei && formik.errors.Imei},
                  {
                    'is-valid': formik.touched.Imei && !formik.errors.Imei,
                  }
                )}
                autoComplete='off'
              />

              {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}

          <div className='row mt-5'>
            {/* end::Input group */}
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Loại cấu hình</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('Control')}
                onChange={(e) => {
                  setIsShowControlDevice(e.target.value === '2')
                  formik.setFieldValue('Control', e.target.value)
                }}
                className={clsx(
                  'form-select form-select-solid fw-bolder',
                  {'is-invalid': formik.touched.Control && formik.errors.Control},
                  {
                    'is-valid': formik.touched.Control && !formik.errors.Control,
                  }
                )}
              >
                {listTypeConfig?.map((m: TypeConfig) => (
                  <option key={m.Id} value={m.Command}>
                    {m.Name}
                  </option>
                ))}
              </select>

              {/* end::Input */}
            </div>
          </div>

          {isShowControlDevice && (
            <div className='row mb-7 mt-5'>
              {/* end::Input group */}
              <div className='col-12'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Lệnh điều khiển</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                  {...formik.getFieldProps('Value')}
                  onChange={(e) => {
                    formik.setFieldValue('Value', e.target.value)
                  }}
                >
                  <option value=''>Vui lòng chọn lệnh điều khiển</option>
                  <option value='2'>Dừng</option>
                  <option value='3'>Phát tiếp</option>
                </select>

                {/* end::Input */}
              </div>
            </div>
          )}

          {/* begin::Input group */}
          {!isShowControlDevice && (
            <div className='row mb-7 mt-5'>
              <div className='col-12'>
                {/* begin::Input */}
                <label className='required fw-bold fs-6 mb-2'>Giá trị</label>
                <textarea
                  placeholder='Giá trị'
                  {...formik.getFieldProps('Value')}
                  name='Value'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.Value && formik.errors.Value},
                    {
                      'is-valid': formik.touched.Value && !formik.errors.Value,
                    }
                  )}
                  autoComplete='off'
                />
                {/* end::Input */}
                {formik.touched.Value && formik.errors.Value && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Value}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center '>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Hủy bỏ
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label fw-b'>Gửi lệnh</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <Loading />}
    </>
  )
}

export {DeviceCommandModalForm}
