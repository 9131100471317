import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth'
import {getManufactureById} from '../../../nhacungcap/nhacungcap-list/core/_requests'
import {getDeviceGroupById} from '../../../nhomthietbi/nhomthietbi-list/core/_requests'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {DeviceInfo} from '../core/_models'

type Props = {
  isLoading: boolean
  device: DeviceInfo
}

const DeviceInfoModalForm: FC<Props> = ({device, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {auth} = useAuth()

  const [deviceForEdit] = useState<DeviceInfo>({
    ...device,
  })

  const iTime = device.InitTime?.toString().split('T')[0] || ''
  const wTime = device.WarrantyTime?.toString().split('T')[0] || ''
  const address = `${device.Address?.WardName}, ${device.Address?.DistrictName}, ${device.Address?.CityName}`
  const [deviceGroupName, setDeviceGroupName] = useState<string>('')
  const [manufactureName, setManufactureName] = useState<string>('')

  useEffect(() => {
    getManufactureById(auth?.configs, device.ManufactureId || '').then((data) => {
      setManufactureName(data?.Info || '')
    })
    getDeviceGroupById(auth?.configs, device.GroupID || '').then((data) => {
      setDeviceGroupName(data?.DeviceGroupName || '')
    })
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate({})
  }

  const formik = useFormik({
    initialValues: deviceForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      cancel(true)
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <h2 style={{color: '#A1A9C7', fontSize: 14, fontWeight: 600, lineHeight: '17px'}}>
            THÔNG TIN THIẾT BỊ
          </h2>
          {/* begin::Input group */}
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Tên thiết bị</label>
              <input
                placeholder='Tên thiết bị'
                {...formik.getFieldProps('Info')}
                type='text'
                name='Info'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                autoComplete='off'
                disabled
              />

              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>IMEI</label>
              <input
                placeholder='IMEI'
                {...formik.getFieldProps('Imei')}
                type='string'
                name='Imei'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Imei && formik.errors.Imei},
                  {
                    'is-valid': formik.touched.Imei && !formik.errors.Imei,
                  }
                )}
                autoComplete='off'
                disabled
              />

              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Nhà cung cấp</label>
              <input
                placeholder='Nhà cung cấp'
                type='string'
                value={manufactureName}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled
              />
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Địa bàn phát</label>
              <input
                placeholder='Địa bàn phát'
                type='string'
                name='GroupName'
                value={deviceGroupName}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled
              />
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Kinh độ</label>
              <input
                placeholder='Kinh độ'
                {...formik.getFieldProps('Longitude')}
                type='number'
                name='Longitude'
                onClick={(event) => event.currentTarget.select()}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Longitude && formik.errors.Longitude},
                  {
                    'is-valid': formik.touched.Longitude && !formik.errors.Longitude,
                  }
                )}
                autoComplete='off'
                disabled
              />

              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>Vĩ độ</label>
              <input
                placeholder='Vĩ độ'
                {...formik.getFieldProps('Latitude')}
                type='number'
                name='Latitude'
                onClick={(event) => event.currentTarget.select()}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Latitude && formik.errors.Latitude},
                  {
                    'is-valid': formik.touched.Latitude && !formik.errors.Latitude,
                  }
                )}
                autoComplete='off'
                disabled
              />

              {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Thời gian lắp đặt</label>
              <input
                type='date'
                name='InitTime'
                value={iTime}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled
              />
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Thời hạn bảo hành</label>
              <input
                // {...formik.getFieldProps('WarrantyTime')}
                type='date'
                name='WarrantyTime'
                value={wTime}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled
              />
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Loại thiết bị</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('DeviceType')}
                disabled
              >
                <option value='0'>Cụm thu phát thanh ứng dụng CNTT-VT</option>
                <option value='1'>Bộ số hóa truyền thanh</option>
                <option value='2'>Bảng điện tử ứng dụng CNTT-VT</option>
                <option value='3'>Cụm thu FM</option>
                <option value='4'>Bảng LED</option>
                <option value='5'>Bộ thu tích hợp tự động</option>
                <option value='6'>Bộ thu-phát tích hợp tự động</option>
              </select>
              {/* end::Label */}
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Gói dịch vụ</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('ServiceType')}
                disabled
              >
                <option value='0'>Tiêu chuẩn</option>
                <option value='1'>Nâng cao</option>
              </select>
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Vị trí</label>
              <input
                placeholder='Vị trí'
                type='string'
                value={address}
                title={address}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.CustomerPhone && formik.errors.CustomerPhone},
                  {
                    'is-valid': formik.touched.CustomerPhone && !formik.errors.CustomerPhone,
                  }
                )}
                autoComplete='off'
                disabled
              />

              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Số điện thoại</label>
              <input
                placeholder='Số điện thoại'
                {...formik.getFieldProps('CustomerPhone')}
                type='string'
                name='CustomerPhone'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.CustomerPhone && formik.errors.CustomerPhone},
                  {
                    'is-valid': formik.touched.CustomerPhone && !formik.errors.CustomerPhone,
                  }
                )}
                autoComplete='off'
                disabled
              />

              {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}
          <div style={{display: 'flex'}}>
            <label className='fw-bold fs-6 mb-2' style={{marginTop: '2px'}}></label>
            <hr style={{flex: 1}} />
          </div>

          <h2
            className=''
            style={{color: '#A1A9C7', fontSize: 14, fontWeight: 600, lineHeight: '17px'}}
          >
            TIÊU ĐỀ
          </h2>
          {/* begin::Input group */}
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Firmware</label>
              <input
                placeholder='Firmware'
                {...formik.getFieldProps('Firmware')}
                type='string'
                name='Firmware'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Firmware && formik.errors.Firmware},
                  {
                    'is-valid': formik.touched.Firmware && !formik.errors.Firmware,
                  }
                )}
                autoComplete='off'
                disabled
              />
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Âm lượng</label>
              <input
                placeholder='Âm lượng'
                type='string'
                value={device.Volumne ? `${device.Volumne}%` : ''}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Volumne && formik.errors.Volumne},
                  {
                    'is-valid': formik.touched.Volumne && !formik.errors.Volumne,
                  }
                )}
                autoComplete='off'
                disabled
              />
              {/* end::Label */}
            </div>
            <div className='col-4'></div>
          </div>
          <div className='row mb-5 mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>GSM</label>
              <input
                placeholder='GsmInfo'
                {...formik.getFieldProps('GsmInfo')}
                type='string'
                name='GsmInfo'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.GsmInfo && formik.errors.GsmInfo},
                  {
                    'is-valid': formik.touched.GsmInfo && !formik.errors.GsmInfo,
                  }
                )}
                autoComplete='off'
                disabled
              />
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>IP</label>
              <input
                placeholder='Ip'
                type='string'
                {...formik.getFieldProps('Ip')}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled
              />
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}

              {/* end::Label */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center '>
          <button
            type='submit'
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label fw-b'>Đóng</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {DeviceInfoModalForm}
