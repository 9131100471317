import {SearchOutlined} from '@ant-design/icons'
import {Button, Input, InputRef, Space} from 'antd'
import 'antd/dist/antd.css'
import {ColumnsType, ColumnType, FilterConfirmProps} from 'antd/lib/table/interface'
import moment from 'moment'
import {FC, useMemo, useRef, useState} from 'react'
import Highlighter from 'react-highlight-words'
import {TableComponent} from '../../../../../components'
import {initialQueryState, KTCardBody} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {ThietBiPagination} from '../components/pagination/ThietBiPagination'
import {useQueryRequest} from '../core/QueryRequestProviderNKBDT'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProviderNKBDT'
type Props = {}

const NhatKyBangDienTuTable: FC<Props> = () => {
  const manufactures = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data: any = useMemo(() => manufactures, [manufactures])
  const {state} = useQueryRequest()

  interface DataType {
    key: string
    Id: string
    State: number
    Imei: string
    UpdateTime: Date
    Title: string
    Name: string
    TextContent: string
    MediaContent: string
    UpdateByUser: string
    Url: string
  }

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof DataType
  const [FromTime, setFromTime] = useState<string | undefined>()
  const [ToTime, setToTime] = useState<string | undefined>()
  const [stateT, setStateT] = useState<boolean | null>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const {updateState} = useQueryRequest()

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
          style={{marginBottom: 8, display: 'block'}}
        />
        <div className='row d-flex'>
          <div className='col-6'>
            <Button
              type='primary'
              onClick={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-6'>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters)
                updateFilter(dataIndex, '')
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getColumnSearchState = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <select
          style={{marginBottom: 8}}
          className='form-select form-select-solid fw-bolder p-select'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-user-table-filter='two-step'
          data-hide-search='true'
          name='listState'
          value={stateT ? '1' : '0'}
          onChange={(e) => {
            if ((e.target.value = '1')) setStateT(true)
            else if ((e.target.value = '0')) setStateT(false)
            else setStateT(null)
          }}
        >
          <option value='null'>Tất cả</option>
          <option value='0'>Gửi lỗi</option>
          <option value='1'>Gửi thành công</option>
        </select>
        <Space>
          <Button
            type='primary'
            onClick={() => {
              updateFilter(dataIndex, stateT)
            }}
            icon={<SearchOutlined />}
            size='small'
            className='w-100'
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              updateFilter(dataIndex, null)
            }}
            size='small'
            className='w-100'
          >
            Làm mới
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getColumnSearchTime = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <div className='row d-flex'>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Từ ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Từ ngày'
              name='FromTime'
              value={FromTime}
              onChange={(e) => {
                if (ToTime && new Date(e.target.value) > new Date(ToTime)) setIsValid(false)
                else setIsValid(true)
                setFromTime(e.target.value)
              }}
            />
            {/* {!isValidTimeFrom && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Từ ngày không được để trống
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Đến ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Đến ngày'
              name='ToTime'
              value={ToTime}
              onChange={(e) => {
                if (FromTime && new Date(e.target.value) < new Date(FromTime)) setIsValid(false)
                else setIsValid(true)
                setToTime(e.target.value)
              }}
            />
            {/* {!isValidTimeTo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Đến ngày không được để trống
                  </span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className='row'>
          {!isValid && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  Đến ngày phải lớn hơn Từ ngày
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='row d-flex justify-content-center'>
          <div className='col-4'>
            <Button
              type='primary'
              onClick={() => {
                let tmp: any = {
                  ...((state.filter as object) || {
                    FromTime: null,
                    ToTime: null,
                    Name: '',
                    Title: '',
                    TextContent: '',
                    MediaContent: '',
                    State: null,
                    UpdateByUser: '',
                  }),
                }
                tmp['FromTime'] = FromTime ? `${FromTime}T00:00:00Z` : null
                tmp['ToTime'] = ToTime ? `${ToTime}T23:59:59Z` : null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-4'>
            <Button
              onClick={() => {
                setFromTime('')
                setToTime('')
                clearFilters && handleReset(clearFilters)
                let tmp: any = {
                  ...((state.filter as object) || {
                    FromTime: null,
                    ToTime: null,
                    Name: '',
                    Title: '',
                    TextContent: '',
                    MediaContent: '',
                    State: null,
                    UpdateByUser: '',
                  }),
                }
                tmp['FromTime'] = null
                tmp['ToTime'] = null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const updateFilter = (dataIndex: any, value: any) => {
    let tmp: any = {
      ...((state.filter as object) || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Title: '',
        TextContent: '',
        MediaContent: '',
        State: null,
        UpdateByUser: '',
      }),
    }
    tmp[dataIndex] = value
    updateState({
      filter: tmp,
      ...initialQueryState,
    })
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      key: '1',
      render: (text, record, index) => index + 1 + (state.currentPage - 1) * state.pageSize,
      align: 'center',
      width: '5%',
    },
    {
      title: 'Tên bảng',
      dataIndex: 'Name',
      key: '2',
      ...getColumnSearchProps('Name'),
    },
    {
      title: 'Thông báo',
      dataIndex: 'Title',
      key: '3',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('Title'),
    },
    {
      title: 'Văn bản',
      dataIndex: 'TextContent',
      key: '4',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('TextContent'),
    },
    {
      title: 'Video',
      dataIndex: 'Url',
      key: '8',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('Url'),
      render: (u: string) => (
        <a href={u} target='_blank'>
          {u}
        </a>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'State',
      align: 'center',
      key: '5',
      ...getColumnSearchState('State'),
      render: (state: boolean) => {
        if (state) {
          return <span>Gửi thành công</span>
        } else {
          return <span>Gửi lỗi</span>
        }
      },
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'UpdateByUser',
      align: 'center',
      ...getColumnSearchProps('UpdateByUser'),
      key: '6',
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'UpdateTime',
      key: '7',
      align: 'center',
      ...getColumnSearchTime('UpdateTime'),
      render: (LastUpdate) => {
        return <p className='center m-0'>{moment(LastUpdate).format('DD/MM/YYYY HH:mm')}</p>
      },
    },
  ]

  return (
    <KTCardBody className='py-4'>
      <TableComponent columns={columns} dataSource={data} />
      <ThietBiPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  )
}

export {NhatKyBangDienTuTable}
