import { DownloadOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { BtnRefetch } from '../../../../../../components'
import { useAuth } from '../../../../../modules/auth/core/Auth'
import { Role } from '../../../../../modules/auth/core/_models'
import { useQueryRequest } from '../../../../nhomnguoidung/nhomnguoidung-list/core/QueryRequestProvider'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse, useQueryResponseData } from '../../core/QueryResponseProvider'
import { ExportUserGroups } from '../../core/_requests'
import { UsersListSearchComponent } from './UsersListSearchComponent'
import {PlusOutlined} from '@ant-design/icons'

const UserGroupsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {auth, currentUser} = useAuth()
  const {refetch} = useQueryResponse()
  const DEVICE_GROUP_EDIT = currentUser?.Roles.find((item: Role) => {
    return item.Value === 15
  })
  const openAddUserModal = () => {
    setItemIdForUpdate('0')
  }
  const data = useQueryResponseData()

  const {state} = useQueryRequest()

  const Export = () => {
    exportUserGroups.mutateAsync()
  }

  const exportUserGroups = useMutation(() => ExportUserGroups(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `danhsachnhomthietbi.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <UsersListSearchComponent />
      {/* <UsersListFilter /> */}

      {/* begin::Export */}
      <BtnRefetch refetch={refetch} />

      <button
        type='button'
        className='btn btn-primary me-3'
        onClick={Export}
        disabled={data.length === 0}
      >
        <DownloadOutlined />
        Xuất dữ liệu
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {DEVICE_GROUP_EDIT && (
        <button type='button' className='btn btn-primary bt-button ' onClick={openAddUserModal}>
          <PlusOutlined />
          Thêm mới
        </button>
      )}
      {/* end::Add user */}
    </div>
  )
}

export { UserGroupsListToolbar }

