import {DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import {Select} from 'antd'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {QueryState} from '../../../../../../_metronic/helpers'
import {BtnRefetch} from '../../../../../../components'
import compareLabelMatch from '../../../../../../untils/compare-label-match'
import {City, Role} from '../../../../../modules/auth'
import {useAuth} from '../../../../../modules/auth/core/Auth'
import {
  getDistrictByCityId,
  getWardByDistrictId,
} from '../../../../diachi/diachi-list/core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse, useQueryResponseData} from '../../core/QueryResponseProvider'
import {District, Ward} from '../../core/_models'
import {ExportMaDinhDanh, getProvinces} from '../../core/_requests'

const MaDinhDanhListHeader = () => {
  const {auth, currentUser} = useAuth()
  const {state, updateState} = useQueryRequest()

  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [cityIdSelected, setCityIdSelected] = useState<string>('')
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [districtIdSelected, setDistrictIdSelected] = useState<string>('')
  const [lstWard, setLstWard] = useState<Array<Ward>>([])
  const [wardIdSelected, setWardIdSelected] = useState<string>('')
  const initialQueryState: QueryState = {
    currentPage: state.currentPage,
    pageSize: state.pageSize,
    totalPages: 1,
    totalItems: 0,
  }

  const data = useQueryResponseData()
  const {refetch} = useQueryResponse()
  const VIEW_MA_DINH_DANH = currentUser?.Roles.find((item: Role) => {
    return item.Value === 64
  })
  const {setItemIdForUpdate} = useListView()
  const [isLoading, setIsLoading] = useState(false)
  const openAddUserModal = () => {
    setItemIdForUpdate('0')
  }

  useEffect(() => {
    getProvinces(auth?.configs).then((data) => {
      setLstCity([{CityName: 'Tất cả', CityId: ''}, ...data])
      if (data.length > 0) {
        setCityIdSelected(
          (localStorage.getItem('diachi') &&
            JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh) ||
            data[0].CityId ||
            ''
        )
        getDistrictByCityId(
          auth?.configs,
          (localStorage.getItem('diachi') &&
            JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh) ||
            data[0]?.CityId
        ).then((res: any) => {
          setLstDistrict(res)
          if (
            localStorage.getItem('diachi') &&
            JSON.parse(localStorage.getItem('diachi') ?? '')?.huyen
          ) {
            setDistrictIdSelected(JSON.parse(localStorage.getItem('diachi') ?? '').huyen)
            getWardByDistrictId(
              auth?.configs,
              JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh,
              JSON.parse(localStorage.getItem('diachi') ?? '')?.huyen
            ).then((res: any) => {
              setLstWard(res)
              setWardIdSelected(JSON.parse(localStorage.getItem('diachi') ?? '')?.xa || '')
            })
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    if (cityIdSelected.length >= 0) {
      let tmp: any = {
        ...((state.filter as object) || {
          Location: {
            CityId: cityIdSelected,
            DistrictId: districtIdSelected,
          },
          FromTime: null,
          ToTime: null,
          Title: '',
          DeviceGroupName: '',
          State: null,
          CreateByUser: '',
        }),
      }
      tmp['Location'] = {
        CityId: cityIdSelected,
        DistrictId: districtIdSelected,
        WardId: wardIdSelected,
      }
      updateState({
        search: Math.random().toString(),
        filter: tmp,

        ...initialQueryState,
      })
    }

    setTimeout(() => {
      if (cityIdSelected || districtIdSelected || wardIdSelected) {
        localStorage.setItem(
          'diachi',
          JSON.stringify({tinh: cityIdSelected, huyen: districtIdSelected, xa: wardIdSelected})
        )
      }
    }, 500)
  }, [cityIdSelected, districtIdSelected, wardIdSelected])

  const changeCity = (cId: string) => {
    setCityIdSelected(cId)
    setLstDistrict([])
    setLstWard([])
    getDistrictByCityId(auth?.configs, cId).then((res: any) => {
      setLstDistrict(res)
    })
    setDistrictIdSelected('')
    setWardIdSelected('')
  }

  const changeDistrict = (dId: string) => {
    if (dId) {
      setDistrictIdSelected(dId)
      setLstWard([])
      getWardByDistrictId(auth?.configs, cityIdSelected || '', dId || '').then((res: any) => {
        setLstWard(res)
      })
      setWardIdSelected('')
    } else {
      setWardIdSelected('')
      setDistrictIdSelected('')
      setLstWard([])
    }
  }

  const Export = () => {
    setIsLoading(true)
    exportUser.mutateAsync()
  }

  const exportUser = useMutation(() => ExportMaDinhDanh(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `danhsachmadinhdanh.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setIsLoading(false)
    },
  })

  return (
    <div className='card-header border-0 pt-6 nguoi-dung-body'>
      {/* <BanTinListSearchComponent /> */}
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative'>
          {/* Quản lý bản tin */}
          <div className='div-select-city'>
            <label className='fw-bold fs-6 mb-2'>Tỉnh:</label>
            <Select
              style={{width: '180px'}}
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              value={cityIdSelected}
              onChange={(value) => changeCity(value)}
              options={lstCity.map((item) => ({label: item.CityName, value: item.CityId}))}
              showSearch
              filterOption={compareLabelMatch}
            />
          </div>
          <div className='div-select-city'>
            <label className='fw-bold fs-6 mb-2'>Huyện:</label>
            <Select
              style={{width: '180px'}}
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              value={districtIdSelected}
              onChange={(value) => changeDistrict(value)}
              options={[
                {
                  label: 'Tất cả',
                  value: '',
                },
                ...lstDistrict?.map((item) => ({
                  label: item.DistrictName,
                  value: item.DistrictId,
                })),
              ]}
              showSearch
              filterOption={compareLabelMatch}
            />
          </div>
          <div className='div-select-city'>
            <label className='fw-bold fs-6 mb-2'>Xã:</label>
            <Select
              style={{width: '180px'}}
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              value={wardIdSelected}
              onChange={(value) => setWardIdSelected(value)}
              options={[
                {
                  label: 'Tất cả',
                  value: '',
                },
                ...lstWard?.map((item) => ({
                  label: item.WardName,
                  value: item.WardId,
                })),
              ]}
              showSearch
              filterOption={compareLabelMatch}
            />
          </div>
        </div>
        {/* end::Search */}
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {/* begin::Export */}
          <BtnRefetch refetch={refetch} />
          <button
            type='button'
            className='btn btn-primary me-3'
            style={{marginLeft: '0.75rem'}}
            onClick={Export}
          >
            <DownloadOutlined />
            Xuất dữ liệu
          </button>
          {/* end::Export */}
          {/* begin::Add user */}
          {VIEW_MA_DINH_DANH && (
            <button type='button' className='btn btn-primary bt-button' onClick={openAddUserModal}>
              <PlusOutlined />
              Thêm mới
            </button>
          )}
          {/* end::Add user */}
        </div>
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {MaDinhDanhListHeader}
