import {createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState} from 'react'
import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  ID,
} from '../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import {City, District, Ward} from './_models'

type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED

  citySelected?: City
  setCitySelected: Dispatch<SetStateAction<City>>
  districtSelected?: District
  setDistrictSelected: Dispatch<SetStateAction<District>>
  wardSelected?: Ward
  setWardSelected: Dispatch<SetStateAction<Ward>>

  itemIdForUpdate?: string
  setItemIdForUpdate: Dispatch<SetStateAction<string>>
  itemIdForInfo?: string
  setItemIdForInfo: Dispatch<SetStateAction<string>>

  isAllSelected: boolean
  disabled: boolean

  selectedObject: any
  setSelectedObject: Dispatch<SetStateAction<any>>
}

const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIdForInfo: () => {},
  setCitySelected: () => {},
  setDistrictSelected: () => {},
  setWardSelected: () => {},
  isAllSelected: false,
  disabled: false,

  selectedObject: {},
  setSelectedObject: () => {},
}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<string>(
    initialListView.itemIdForUpdate ? initialListView.itemIdForUpdate : ''
  )
  const [itemIdForInfo, setItemIdForInfo] = useState<string>(
    initialListView.itemIdForInfo ? initialListView.itemIdForInfo : ''
  )
  const [citySelected, setCitySelected] = useState<City>(initialListView.citySelected || {})
  const [districtSelected, setDistrictSelected] = useState<District>(
    initialListView.districtSelected || {}
  )
  const [wardSelected, setWardSelected] = useState<Ward>(initialListView.wardSelected || {})
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const [selectedObject, setSelectedObject] = useState<Object>(initialListView.selectedObject)

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        itemIdForInfo,
        setItemIdForInfo,
        citySelected,
        setCitySelected,
        districtSelected,
        setDistrictSelected,
        wardSelected,
        setWardSelected,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },

        selectedObject,
        setSelectedObject,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
