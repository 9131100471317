import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {ActivityDrawer} from '../partials'
import {Content} from './components/Content'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {PageDataProvider} from './core'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div
            id='kt_content'
            className='content d-flex flex-column flex-column-fluid'
            style={{padding: '20px 0'}}
          >
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />

      {/* <ScrollTop /> */}
    </PageDataProvider>
  )
}

export {MasterLayout}
