import { FC, useState, createContext, useContext, useMemo, SetStateAction, Dispatch } from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
} from '../../../../../_metronic/helpers'
import { Role } from '../../../../modules/auth'
import { useQueryResponse, useQueryResponseData } from './QueryResponseProvider'
import { RoleGroup } from './_models'

type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: string
  setItemIdForUpdate: Dispatch<SetStateAction<string>>
  isAllSelected: boolean
  disabled: boolean

  selectedObject: RoleGroup
  setSelectedObject: Dispatch<SetStateAction<RoleGroup>>

  selectedRoles: Array<Role>
  setSelectedRoles: Dispatch<SetStateAction<Array<Role>>>

  selectedObjectTable: RoleGroup
  setSelectedObjectTable: Dispatch<SetStateAction<RoleGroup>>
}

const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },

  isAllSelected: false,
  disabled: false,

  selectedObject: {},
  setSelectedObject: () => { },

  selectedRoles: [],
  setSelectedRoles: () => { },

  selectedObjectTable: {},
  setSelectedObjectTable: () => { },
}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<string>(initialListView.itemIdForUpdate ? initialListView.itemIdForUpdate : "")
  const { isLoading } = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const [selectedObject, setSelectedObject] = useState<Object>(initialListView.selectedObject)
  const [selectedRoles, setSelectedRoles] = useState<Array<Role>>(initialListView.selectedRoles)
  const [selectedObjectTable, setSelectedObjectTable] = useState<Object>(initialListView.selectedObjectTable)
  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },

        selectedObject,
        setSelectedObject,
        selectedRoles,
        setSelectedRoles,
        selectedObjectTable,
        setSelectedObjectTable,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProvider, useListView }
