import { Response } from '../../../../../_metronic/helpers'
export type DeviceGroup = {
  Id?: string
  DeviceGroupName?: string
  Info?: string
  ImeiMaster?: string
  CreateTime?: Date
  UpdateTime?: Date
  Address?: Address
  CreateByUser?: string,
  DeviceType?: number,
  StreamState?: any,
  Longitude?: number,
  Latitude?: number,
  IsActive?: boolean,
  NguonID?: string,
  CauHinhDongBos?: Array<string>
  StreamType?: number
}

export type Address = {
  CityName?: string
  DistrictName?: string
  WardName?: string
}

export type DeviceGroupQueryResponse = Response<Array<DeviceGroup>>

export const initialDeviceGroup: DeviceGroup = {
  IsActive: true
}
