import { RoleModalForm } from './RoleModalForm'
import { useListView } from '../core/ListViewProvider'

const RoleModalFormWrapper = () => {
  const { selectedRoles } = useListView()

  return <RoleModalForm roles={selectedRoles || []} />

}

export { RoleModalFormWrapper }
