import {ReactNode} from 'react'

enum ForgotQuestion {
  QUESTION_1 = 'Thành phố bạn sinh ra tên là gì?',
  QUESTION_2 = 'Tên bài hát mà bạn yêu thích là gì?',
  QUESTION_3 = 'Bố mẹ bạn gặp nhau lần đầu tiên ở đâu?',
  QUESTION_4 = 'Loài vật mà bạn yêu thích nhất là gì?',
  QUESTION_5 = 'Biệt danh lúc nhỏ của bạn là gì?',
  QUESTION_6 = 'Ca sĩ/nhóm nhạc yêu thích của bạn là gì?',
  QUESTION_7 = 'Điện thoại bạn mua đầu tiên là của hãng nào?',
  QUESTION_8 = 'Trò chơi mà bạn yêu thích nhất là gì?',
  QUESTION_9 = 'Người bạn thân nhất của bạn tên là gì?',
  QUESTION_10 = 'Công ty đầu tiên mà bạn làm việc tên là gì?',
}

export const mapForgotQuestion = new Map<string, {label: ReactNode; value: ForgotQuestion}>([
  [ForgotQuestion.QUESTION_1, {label: ForgotQuestion.QUESTION_1, value: ForgotQuestion.QUESTION_1}],
  [ForgotQuestion.QUESTION_2, {label: ForgotQuestion.QUESTION_2, value: ForgotQuestion.QUESTION_2}],
  [ForgotQuestion.QUESTION_3, {label: ForgotQuestion.QUESTION_3, value: ForgotQuestion.QUESTION_3}],
  [ForgotQuestion.QUESTION_4, {label: ForgotQuestion.QUESTION_4, value: ForgotQuestion.QUESTION_4}],
  [ForgotQuestion.QUESTION_5, {label: ForgotQuestion.QUESTION_5, value: ForgotQuestion.QUESTION_5}],
  [ForgotQuestion.QUESTION_6, {label: ForgotQuestion.QUESTION_6, value: ForgotQuestion.QUESTION_6}],
  [ForgotQuestion.QUESTION_7, {label: ForgotQuestion.QUESTION_7, value: ForgotQuestion.QUESTION_7}],
  [ForgotQuestion.QUESTION_8, {label: ForgotQuestion.QUESTION_8, value: ForgotQuestion.QUESTION_8}],
  [ForgotQuestion.QUESTION_9, {label: ForgotQuestion.QUESTION_9, value: ForgotQuestion.QUESTION_9}],
  [
    ForgotQuestion.QUESTION_10,
    {label: ForgotQuestion.QUESTION_10, value: ForgotQuestion.QUESTION_10},
  ],
])

export const getForgotQuestion = () => {
  return Object.values(ForgotQuestion)
    .filter((item) => typeof item === 'string')
    .map((item: any) => mapForgotQuestion.get(item))
}
