import {useQuery} from 'react-query'
import {DaiTruyenThanhEditModalForm} from './DaiTruyenThanhEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {getDeviceGroupById} from '../core/_requests'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import Swal from 'sweetalert2'
import {useQueryResponse} from '../core/QueryResponseProvider'

const DaiTruyenThanhEditModalFormWrapper = () => {
  const {auth} = useAuth()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = itemIdForUpdate !== '0'
  const {refetch} = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate('')
  }
  const {
    isLoading,
    data: deviceGroup,
    error,
  } = useQuery(
    `deviceGroup/get-deviceGroupById-${itemIdForUpdate}`,
    () => {
      return getDeviceGroupById(auth?.configs, itemIdForUpdate || '')
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Không tìm thấy dữ liệu',
          text: ' ',
          confirmButtonText: 'Đóng lại',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            cancel(true)
          }
        })
      },
    }
  )

  if (itemIdForUpdate === '0') {
    return <DaiTruyenThanhEditModalForm isUserLoading={isLoading} deviceGroup={{Id: '0'}} />
  }

  if (!isLoading && !error && deviceGroup) {
    return (
      <DaiTruyenThanhEditModalForm
        isUserLoading={isLoading}
        deviceGroup={deviceGroup || {Id: '0'}}
      />
    )
  }

  return <Loading />
}

export {DaiTruyenThanhEditModalFormWrapper}
