import {FC, useEffect, useState} from 'react'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {City, useAuth} from '../../../../../modules/auth'
import {getDistrictByCityId} from '../../../../diachi/diachi-list/core/_requests'
import {District} from '../../../../MAP_New2/core/_models'
import {getProvinces} from '../../../../MAP_New2/core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProviderNKBDT'
import {ListSearchComponent} from './ListSearchComponent'

type Props = {
  changeTab: any
}
const NhatKyBangDienTuListHeader: FC<Props> = ({changeTab}) => {
  const {auth, citySelectedAuth} = useAuth()
  const {state, updateState} = useQueryRequest()
  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [cityIdSelected, setCityIdSelected] = useState<string>('')
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [districtIdSelected, setDistrictIdSelected] = useState<string>('')

  useEffect(() => {
    getProvinces(auth?.configs).then((data) => {
      setLstCity(data)
      if (data.length > 0) {
        let dId = data[0]?.CityId
        if (citySelectedAuth) {
          setCityIdSelected(citySelectedAuth.CityId || '')
          dId = citySelectedAuth.CityId
        } else setCityIdSelected(data[0].CityId || '')
        getDistrictByCityId(auth?.configs, dId || '').then((res) => {
          setLstDistrict(res)
        })
      }
    })
  }, [])

  const changeCity = (cId: string) => {
    setCityIdSelected(cId)
    setLstDistrict([])
    getDistrictByCityId(auth?.configs, cId).then((res) => {
      setLstDistrict(res)
    })
    setDistrictIdSelected('')
  }

  useEffect(() => {
    if (cityIdSelected.length > 0) {
      let tmp: any = {
        ...((state.filter as object) || {
          Location: {
            CityId: cityIdSelected,
            DistrictId: '',
          },
          Name: '',
          Title: '',
          TextContent: '',
          MediaContent: '',
          UpdateByUser: '',
          State: null,
          FromTime: null,
          ToTime: null,
        }),
      }
      tmp['Location'] = {
        CityId: cityIdSelected,
        DistrictId: districtIdSelected,
      }
      updateState({
        search: Math.random().toString(),
        filter: tmp,
        ...initialQueryState,
      })
    }
  }, [cityIdSelected, districtIdSelected])

  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-title'>
        {/* begin::Search */}
        <p className='m-r-10'>Nhật ký hoạt động</p>
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Tỉnh:</label>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={cityIdSelected}
            onChange={(e) => changeCity(e.target.value)}
          >
            {lstCity.map((c: City) => {
              return (
                <option key={c.CityId} value={c.CityId}>
                  {c.CityName}
                </option>
              )
            })}
          </select>
        </div>
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Huyện:</label>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={districtIdSelected}
            onChange={(e) => setDistrictIdSelected(e.target.value)}
          >
            <option value=''>Tất cả</option>
            {lstDistrict.map((c: District) => {
              return (
                <option key={c.DistrictId} value={c.DistrictId}>
                  {c.DistrictName}
                </option>
              )
            })}
          </select>
        </div>
        {/* end::Search */}
      </div>
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ListSearchComponent changeTab={changeTab} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {NhatKyBangDienTuListHeader}
