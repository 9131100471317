export enum DeviceStatus {
  INIT,
  STOP,
  RUNNING,
  DISCONNECT,
  MIC,
  FM,
  SCHEDULE_OFFLINE,
  MEETING,
  MAT_NGUON,
  LOA_HO_MACH,
}

export const mapDeviceStatus = new Map<
  number,
  {label: string; value: DeviceStatus; color: string; bgColor: string}
>([
  [
    DeviceStatus.INIT,
    {label: 'Đang kết nối', value: DeviceStatus.INIT, color: '#50cd89', bgColor: '#e8fff3'},
  ],
  [
    DeviceStatus.STOP,
    {
      label: 'Tạm dừng hoạt động',
      value: DeviceStatus.STOP,
      color: 'rgb(245 170 27)',
      bgColor: 'rgb(255 253 232)',
    },
  ],
  [
    DeviceStatus.RUNNING,
    {label: 'Đang phát', value: DeviceStatus.RUNNING, color: '#2E1C32', bgColor: '#E5EBEE'},
  ],
  [
    DeviceStatus.DISCONNECT,
    {
      label: 'Đang có sự cố',
      value: DeviceStatus.DISCONNECT,
      color: '#f1416c',
      bgColor: 'rgb(255, 232, 239)',
    },
  ],
  [
    DeviceStatus.MIC,
    {
      label: 'Mic',
      value: DeviceStatus.MIC,
      color: '#fa8c16',
      bgColor: '#ffe7ba',
    },
  ],
  [
    DeviceStatus.FM,
    {
      label: 'Thu FM',
      value: DeviceStatus.FM,
      color: '#1677ff',
      bgColor: '#bae0ff',
    },
  ],
  [
    DeviceStatus.SCHEDULE_OFFLINE,
    {
      label: 'Chạy lịch offline',
      value: DeviceStatus.SCHEDULE_OFFLINE,
      color: '#8c8c8c',
      bgColor: '#fafafa',
    },
  ],
  [
    DeviceStatus.MEETING,
    {
      label: 'Meeting',
      value: DeviceStatus.MEETING,
      color: '#50cd89',
      bgColor: '#e8fff3',
    },
  ],
  [
    DeviceStatus.MAT_NGUON,
    {
      label: 'Mất nguồn điện',
      value: DeviceStatus.MAT_NGUON,
      color: '#f1416c',
      bgColor: 'rgb(255, 232, 239)',
    },
  ],
  [
    DeviceStatus.LOA_HO_MACH,
    {
      label: 'Loa hở mạch',
      value: DeviceStatus.LOA_HO_MACH,
      color: '#f1416c',
      bgColor: 'rgb(255, 232, 239)',
    },
  ],
])
