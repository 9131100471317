import {Modal, Radio, RadioChangeEvent, Space} from 'antd'
import {useState} from 'react'
import {controlBitrateDeviceGroupInfo} from '../../../../../app/pages/nhomthietbi/nhomthietbi-list/core/_requests'
import {useAuth} from '../../../../../app/modules/auth'
import Swal from 'sweetalert2'

interface CallSOSModalProps {
  isModalOpen: boolean
  handleCancel: any
  isJoin?: boolean
}

const BitrateModal = ({isModalOpen, handleCancel, isJoin}: CallSOSModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const {auth} = useAuth()
  const [value, setValue] = useState<number>(32000)

  const handleOk = () => {
    localStorage.setItem('bitrate', value.toString())

    controlBitrateDeviceGroupInfo(
      auth?.configs,
      value,
      (res: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Cập nhật thông tin thành công',
          text: ' ',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      (message: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Cập nhật thông tin thất bại',
          text: ' ',
          confirmButtonText: 'Đóng lại',
          confirmButtonColor: '#009ef7',
        })
      }
    )

    handleCancel()
  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  return (
    <>
      <Modal
        title='Chất lượng âm thanh khi đặt lịch'
        open={isModalOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        okText='Đồng ý'
        cancelText='Hủy bỏ'
        onCancel={handleCancel}
        className='nguoi-dung-body'
      >
        <Space direction='vertical'>
          <Radio.Group onChange={onChange} value={value} style={{display: 'inline-grid'}}>
            <Radio value={32000}>32 kbps</Radio>
            <Radio value={48000}>48 kbps</Radio>
            <Radio value={64000}>64 kbps</Radio>
            <Radio value={96000}>96 kbps</Radio>
            <Radio value={128000}>128 kbps</Radio>
          </Radio.Group>
          <span className='fw-bold fs-6 mb-2'>
          * Lưu ý: Tất cả các đài truyền thanh bạn quản lý sẽ được cấu hình theo chất lượng âm thanh này!
          </span>
        </Space>
      </Modal>
    </>
  )
}

export default BitrateModal
