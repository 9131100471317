const API_URL = process.env.REACT_APP_API_URL
const TITLE_FOOTER = process.env.REACT_APP_TITLE_FOOTER
const IMG_LOGO_LOGIN = process.env.REACT_APP_IMG_LOGO_LOGIN
const IMG_LOGO_APP = process.env.REACT_APP_IMG_LOGO_APP
const CITY_ID = process.env.REACT_APP_CITY_ID
const LATITUDE = process.env.REACT_APP_LATITUDE
const LONGITUDE = process.env.REACT_APP_LONGITUDE
// const LOCATION_URL = process.env.REACT_APP_LOCATION_URL
const TEXT_APP = process.env.REACT_APP_TEXT_APP
const TEXT_LOGO_APP = process.env.REACT_APP_TEXT_LOGO_APP
const TEXT_LOGO_LOGIN = process.env.REACT_APP_TEXT_LOGO_LOGIN
const SHORTCURT_ICON = process.env.REACT_APP_SHORTCURT_ICON
const HOT_LINE = process.env.REACT_APP_HOT_LINE
const LOCAL_MEET = process.env.REACT_APP_LOCAL_MEET
const LIVE_STREAM_URL = process.env.REACT_APP_LIVE_STREAM_URL
const URL_LIVE = process.env.REACT_APP_URL_LIVE
const rtcUrlWhip = process.env.REACT_APP_URL_RTCURLWHIP
const rtcUrlWhep = process.env.REACT_APP_URL_RTCURLWHEP

export interface DynamicConfig {
  apiUrl: string
  titleFooter: string
  imgLogoLogin: string
  imgLogoApp: string
  textApp: string
  textLogoApp: string
  textLogoLogin: string
  cityId: string
  latitude: number
  longitude: number
  shortcutIcon: string
  hotline: string
  localMeet: string
  rtcUrlWhip: string
  rtcUrlWhep: string
  liveStreamUrl: string
  urlLive: string
}

export const defaultConfig: DynamicConfig = {
  apiUrl: API_URL || '',
  titleFooter: TITLE_FOOTER || '',
  imgLogoLogin: IMG_LOGO_LOGIN || '',
  imgLogoApp: IMG_LOGO_APP || '',
  textApp: TEXT_APP || '',
  textLogoApp: TEXT_LOGO_APP || '',
  textLogoLogin: TEXT_LOGO_LOGIN || '',
  cityId: CITY_ID || '',
  latitude: parseFloat(LATITUDE || '21.03283765261486'),
  longitude: parseFloat(LONGITUDE || '105.79088416895725'),
  shortcutIcon: SHORTCURT_ICON || '',
  hotline: HOT_LINE || '024.6666.8989',
  localMeet: LOCAL_MEET || '',
  rtcUrlWhip: rtcUrlWhip || '',
  rtcUrlWhep: rtcUrlWhep || '',
  liveStreamUrl: LIVE_STREAM_URL || '',
  urlLive: URL_LIVE || '',
}

export const dynamicConfigUrl = '/config.json'
