import {useEffect} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {CloseOutlined} from '@ant-design/icons'

const ListUserModal = () => {
  useEffect(() => {
    // document.body.classList.add('modal-open')
    // return () => {
    //   document.body.classList.remove('modal-open')
    // }
  }, [])

  const closeListUserModal = () => {
    var modal = document.getElementById('modalListUser')
    var modalBackdrop = document.getElementById('modalBackdrop')
    if (modal && modalBackdrop) {
      var modalContent = document.getElementById('modalListUserContent')
      if (modalContent) {
        modal.classList.remove('show')
        modal.classList.remove('d-block')
        modalBackdrop.classList.add('display-node')
      }
    }
  }

  return (
    <>
      <div className='modal fade' id='modalListUser' role='dialog' tabIndex={-1} aria-modal='true'>
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 id='modalH2' className='fw-bolder'>
                Thông tin nhóm
              </h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                style={{cursor: 'pointer'}}
                onClick={closeListUserModal}
              >
                <CloseOutlined style={{fontSize: '16px'}} />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body'>
              <div className='row' style={{alignItems: 'center'}}>
                <div id='modalListUserContent' className='col-12'></div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div id='modalBackdrop' className='modal-backdrop fade show display-node'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {ListUserModal}
