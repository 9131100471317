import axios, { AxiosResponse } from 'axios'
import { QueryState } from '../../../../../_metronic/helpers'
import { DynamicConfig } from '../../../../../configuration/config'
import { DataQueryResponse } from './_models'

// const API_URL = process.env.REACT_APP_API_URL
// const URL = `${API_URL}/notification`

const notification = '/notification'

const get10Notification = (configs: DynamicConfig): Promise<DataQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${notification}/get-notifyByFilter`,
      {
        "Header": "",
        "FromTime": null,
        "ToTime": null,
        "Content": "",
        "ReadStatus": null,
        "NotifyType": null
      }, {
      headers: {
        currentPage: 1,
        pageSize: 10
      }
    })
    .then((d: AxiosResponse<DataQueryResponse>) => d.data)
}

const getNotifications = (configs: DynamicConfig, state: QueryState): Promise<DataQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${notification}/get-notifyByFilter`,
      state.filter || {
        "Header": "",
        "FromTime": null,
        "ToTime": null,
        "Content": "",
        "ReadStatus": null,
        "NotifyType": null
      }, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize
      }
    })
    .then((d: AxiosResponse<DataQueryResponse>) => d.data)
}

// const getUserGroupById = (id: string): Promise<UserGroupsQueryResponse> => {
//   return axios
//     .post(`${USER_URL}/get-userGroupByGroupId`, {
//       UserGroupIds: [id],
//       Filter: {
//         UserGroupName: '',
//         Info: "",
//         CreateByUser: "",
//         Username: ""
//       }
//     })
//     .then((d: AxiosResponse<UserGroupsQueryResponse>) => d.data)
// }

const updateReadStatusNotify = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${notification}/update-readStatus`, {
    ListId: [id]
  }).then(() => { })
}

// const updateUserGroup = (userGroup: UserGroup): Promise<UserGroup | undefined> => {
//   return axios
//     .put(`${USER_URL}/update-userGroup`, userGroup)
//     .then((response: AxiosResponse<Response<UserGroup>>) => response.data)
//     .then((response: Response<UserGroup>) => response.data)
// }

const deleteNotify = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${notification}/delete-nofity`, {
    ListId: [id]
  }).then(() => { })
}

// const deleteSelectedUserGroups = (userIds: Array<ID>): Promise<void> => {
//   const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
//   return axios.all(requests).then(() => { })
// }

// const getUsersByGroupId = (id: string): Promise<UserGroupsQueryResponse> => {
//   return axios
//     .post(`${API_URL}/users/get-usersByGroupId`, [id], {
//       headers: {
//         currentPage: 1,
//         pageSize: 1000
//       }
//     })
//     .then((d: AxiosResponse<UserGroupsQueryResponse>) => d.data)
// }

// const removeUserInGroup = (id: string, userName: string): Promise<UserGroupsQueryResponse> => {
//   return axios
//     .post(`${USER_URL}/remove-userInGroup`, {
//       Id: id,
//       UserName: userName
//     })
//     .then((d: AxiosResponse<UserGroupsQueryResponse>) => d.data)
// }

const deleteAllNotification = (configs: DynamicConfig): Promise<void> => {
  return axios.put(`${configs.apiUrl}${notification}/delete-allNotify`).then(() => { })
}

const ExportNotification = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${notification}/export-notify`, state.filter || {
      "Header": "",
      "FromTime": null,
      "ToTime": null,
      "Content": "",
      "ReadStatus": null,
      "NotifyType": null
    }, { responseType: "blob" })
    .then((d: AxiosResponse<Blob>) => d.data)
}


export { ExportNotification, deleteAllNotification, deleteNotify, get10Notification, getNotifications, updateReadStatusNotify }
