import React from 'react'
//import {MenuItem} from './MenuItem'
//import {MenuInnerWithSub} from './MenuInnerWithSub'
//import {MegaMenu} from './MegaMenu'
//import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'

export function MenuInner() {
  //const intl = useIntl()
  const { currentUser, logout } = useAuth()
  return (
    <div className='menu-item px-5'>
      <a onClick={logout} className='menu-link px-5'>
        Đăng xuất
      </a>
      {/* <button type='button' onClick={logout} className='btn-logout' title='Đăng xuất'>
        <i className='bi bi-box-arrow-right' style={{ fontSize: '23px' }}></i>
      </button> */}
    </div>
  )
}
