import {KTSVG} from '../../../../../_metronic/helpers'
import {useListViewBDT} from '../core/ListViewProviderBDT'
import {CloseOutlined} from '@ant-design/icons'

const DeviceEditModalHeader = () => {
  const {setItemIdForUpdateLed} = useListViewBDT()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Thông tin thiết bị</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdateLed('')}
        style={{cursor: 'pointer'}}
      >
        <CloseOutlined style={{fontSize: '16px'}} />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {DeviceEditModalHeader}
