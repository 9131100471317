//import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
//import { Query } from 'react-query'
import {ID, QueryState, Response} from '../../../../../_metronic/helpers'
import {Manufacture, ManufacturesQueryResponse} from './_models'
import {DynamicConfig} from '../../../../../configuration/config'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/Manufacture`

const manufacture = '/Manufacture'

const getAllManufacture = (
  configs: DynamicConfig,
  state: QueryState
): Promise<ManufacturesQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${manufacture}/get-manufactureByFilter`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Info: '',
        PhoneNumber: '',
        Address: '',
        CreateByUser: '',
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<ManufacturesQueryResponse>) => d.data)
}

const getManufactureById = (
  configs: DynamicConfig,
  id: string
): Promise<Manufacture | undefined> => {
  return axios
    .post(`${configs.apiUrl}${manufacture}/get-manufactureById`, {
      Id: id,
    })
    .then((d: AxiosResponse<Manufacture>) => d.data)
}

const createManufacture = (
  configs: DynamicConfig,
  _manufacture: Manufacture
): Promise<Manufacture | undefined> => {
  return axios
    .post(`${configs.apiUrl}${manufacture}/create-manufacture`, {
      Info: _manufacture.Info,
      Address: _manufacture.Address,
      PhoneNumber: _manufacture.PhoneNumber,
      Code: _manufacture.Code,
    })
    .then((response: AxiosResponse<Response<Manufacture>>) => response.data)
    .then((response: Response<Manufacture>) => response.data)
}

const updateManufacture = (
  configs: DynamicConfig,
  _manufacture: Manufacture
): Promise<Manufacture | undefined> => {
  return axios
    .post(`${configs.apiUrl}${manufacture}/update-manufacture`, {
      Id: _manufacture.Id,
      Info: _manufacture.Info,
      Address: _manufacture.Address,
      PhoneNumber: _manufacture.PhoneNumber,
      Code: _manufacture.Code,
    })
    .then((response: AxiosResponse<Response<Manufacture>>) => response.data)
    .then((response: Response<Manufacture>) => response.data)
}

const ExportNCC = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${manufacture}/export-manufacture`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Info: '',
        PhoneNumber: '',
        Address: '',
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

export {getAllManufacture, ExportNCC, getManufactureById, createManufacture, updateManufacture}
